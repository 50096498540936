<style>
:root {
  --s-color: #edf2fc;
}

.accordion-item.text-primary > .accordion-header > button {
  color: var(--primary-color);
}

.dx-tag-content {
  background-color: var(--s-color);
}
</style>

<template>
  <div>
    <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
      <div class="offcanvas-header">
        <h4 class="offcanvas-title" id="offcanvasExampleLabel">Поиск по параметрам</h4>
        <button type="button" ref="btnClose" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
      </div>
      <div class="offcanvas-body p-0">
        <div class="p-2" data-simplebar style="">
          <b-accordion flush free>
            <b-accordion-item title="Иски" class="text-primary" visible>
              <div class="row g-3">
                <div class="col-sm-12">
                  <label class="form-label">Судебный участок</label>
                  <DxTagBox
                    :data-source="courtStatesStore"
                    display-expr="number"
                    value-expr="id"
                    class="form-control-sm"
                    v-model="filters.courtStateIds"
                    :show-selection-controls="true"
                    apply-value-mode="useButtons"
                  />
                </div>
                <div class="col-sm-12">
                  <label for="courtDecisionExecutionDocNo" class="form-label">Номер дела</label>
                  <DxTextBox :show-clear-button="true" class="form-control-sm" v-model="filters.courtDecisionExecutionDocNo" />
                </div>
                <div class="col-sm-12">
                  <label for="executionDocNo" class="form-label">Номер исполнительного листа</label>
                  <DxTextBox :show-clear-button="true" class="form-control-sm" v-model="filters.executionDocNo" />
                </div>
                <div class="col-sm-12">
                  <label for="courtDecisionExecutionDocNo" class="form-label">Номер исполнительного производства</label>
                  <DxTextBox :show-clear-button="true" class="form-control-sm" v-model="filters.sspExecutionDocNumber" />
                </div>
                <div class="col-sm-12">
                  <label class="form-label">ФИО ответчика содержит</label>
                  <DxTextBox :show-clear-button="true" class="form-control-sm" v-model="filters.respondentNameContains" />
                </div>
                <div class="col-sm-12">
                  <label class="form-label">Автор иска</label>
                  <DxTagBox
                    :data-source="usersStore"
                    display-expr="name"
                    value-expr="id"
                    class="form-control-sm"
                    :show-selection-controls="true"
                    v-model="filters.writAuthorIds"
                    apply-value-mode="useButtons"
                  />
                </div>
              </div>
            </b-accordion-item>
            <b-accordion-item title="Должники" class="text-primary" visible>
              <div class="row g-3">
                <div class="col-sm-12">
                  <label class="form-label"> ФИО квартиросъемщика содержит </label>
                  <DxTextBox :show-clear-button="true" class="form-control-sm" v-model="filters.renterNameContains" />
                </div>
              </div>
            </b-accordion-item>
          </b-accordion>
        </div>
      </div>
      <div class="offcanvas-foorter border-top p-3 text-center">
        <button class="btn btn btn-primary ml-auto" style="width: 300px" @click="onApplyClick">Применить</button>
        <button class="btn btn b primary ml-auto mt-2" style="width: 300px" @click="resetFilters">Сбросить параметры</button>
      </div>
    </div>
  </div>
</template>

<script>
import createStoreExtend from "@/helpers/grid/store";
import { debtorsFiltersMethods, debtorsFiltersComputed } from "@/state/helpers";
import { usersStore } from "@/helpers/common-store/store";
import DxTagBox from "devextreme-vue/tag-box";

export default {
  name: "DebtorsFiltersComponent",
  emits: ["on-apply-click"],
  components: { DxTagBox },
  computed: {
    ...debtorsFiltersComputed,
  },
  data() {
    return {
      usersStore,
      courtStatesStore: createStoreExtend({
        key: "id",
        loadUrl: `${process.env.VUE_APP_URL}/api/devextreme/courts/courts`,
      }),
      emptyFilters: null,
      filters: {
        courtStateIds: null,
        courtDecisionExecutionDocNo: "",
        respondentNameContains: "",
        sspExecutionDocNumber: "",
        writAuthorIds: null,
      },
    };
  },
  created() {
    this.emptyFilters = Object.assign({}, this.filters);
  },
  beforeMount() {
    this.filters = Object.assign({}, this.currentFilters);
  },
  methods: {
    ...debtorsFiltersMethods,

    onApplyClick() {
      this.applyFilters();
    },

    saveFiltersInternal() {
      this.applyFilters();
    },

    isEmptyFilters() {
      return JSON.stringify(this.emptyFilters) === JSON.stringify(this.filters);
    },

    resetFilters() {
      this.filters = Object.assign({}, this.emptyFilters);
      this.applyFilters();
    },

    applyFilters() {
      this.saveFilters({ filters: Object.assign(Object.assign({}, this.emptyFilters), this.filters) });
      this.$refs.btnClose.click();
    },
  },
};
</script>
