import createStoreExtend from "@/helpers/grid/store";

var courtDecisionTypesStore = createStoreExtend({
  key: "id",
  loadUrl: `${process.env.VUE_APP_URL}/api/devextreme/common/court-decision-types`,
});

var preparationApplicationFormsStore = createStoreExtend({
  key: "id",
  loadUrl: `${process.env.VUE_APP_URL}/api/devextreme/common/preparation-application-forms`,
});

var sspStatusesStore = createStoreExtend({
  key: "id",
  loadUrl: `${process.env.VUE_APP_URL}/api/devextreme/common/ssp-statuses`,
});

var templateGroupsStore = createStoreExtend({
  key: "id",
  loadUrl: `${process.env.VUE_APP_URL}/api/devextreme/common/template-groups`,
});

var documentTypesStore = createStoreExtend({
  key: "id",
  loadUrl: `${process.env.VUE_APP_URL}/api/devextreme/common/document-types`,
});

var agreementTypesStore = createStoreExtend({
  key: "id",
  loadUrl: `${process.env.VUE_APP_URL}/api/devextreme/common/agreement-types`,
});

var writStatesStore = createStoreExtend({
  key: "id",
  loadUrl: `${process.env.VUE_APP_URL}/api/devextreme/common/writ-states`,
});

var usersStore = createStoreExtend({
  key: "id",
  loadUrl: `${process.env.VUE_APP_URL}/api/devextreme/users/users`,
});

var debtorWorkTypesStore = createStoreExtend({
  key: "id",
  loadUrl: `${process.env.VUE_APP_URL}/api/devextreme/common/debtor-work-types`,
});

export { courtDecisionTypesStore, preparationApplicationFormsStore, sspStatusesStore, templateGroupsStore, documentTypesStore, agreementTypesStore, usersStore, writStatesStore, debtorWorkTypesStore };
